import React, { useEffect, useRef, useState } from "react";
import "./ProjectStory.css";
import Default from "../../components/theme/Default/Default";
import AIChat from "../../components/AIChat";
import { FILTER } from "../../images";
import Post from "./Post";
import { IoIosAdd } from "react-icons/io";
import { Icon } from "@iconify/react/dist/iconify.js";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { projectStoryPending } from "../../store/data.action";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[25px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ProjectStory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectStories, setProjectStories] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderbtn, setLoaderbtn] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);

  const [totalPendingStories, setTotalPendingStories] = useState(0);

  const _projectStoryPending = useSelector(
    (state) => state.dataReducer.projectStoryPending
  );
  // console.log("_projectStoryPending", _projectStoryPending);

  const params = useParams();
  // console.log("params", params);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const projectId = params.project_id;

  const createProjectStory = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.project = projectId;
    // console.log(object);

    try {
      setLoaderbtn(true);
      const response = await PostRequest("/project/stories/create", object);

      if (response) {
        setIsOpen(false);
        const clone = [...projectStories];
        clone.unshift(response);
        setProjectStories(clone);
        toast.success("Post created");
      }
      setLoaderbtn(false);
    } catch (error) {
      toast.error(error.message || "An error occurred");
      setLoaderbtn(false);
    }
  };

  const getProjectStories = async (isLoadMore = false) => {
    try {
      setLoader(true);
      const response = await PostRequest("/project/stories", {
        project: projectId,
        skip: isLoadMore ? projectStories.length : 0,
      });

      if (response && isLoadMore) {
        setProjectStories((prevProjectStories) => [
          ...prevProjectStories,
          ...response,
        ]);
      } else {
        setProjectStories(response);
      }

      setLoader(false);
    } catch (error) {
      toast.error(error.message || "An error occurred");
      setLoader(false);
    }
  };

  // const loadPendingStreamCount = async () => {
  //   try {
  //     const count = await PostRequest("/project/stories/pending", {
  //       project: projectId,
  //     });
  //     setTotalPendingStories(count);

  //   } catch (error) {}
  // };

  // const loadPendingStreamCount = () => {
  //   dispatch(projectStoryPending({ project: projectId }));
  // };

  useEffect(() => {
    getProjectStories();
  }, [projectId]);

  useEffect(() => {
    dispatch(projectStoryPending({ project: projectId }));
  }, [projectStories]);

  useEffect(() => {
    if (reachedBottom) {
      getProjectStories(true);
      setReachedBottom(false);
    }
  }, [reachedBottom]);

  const handleScroll = () => {
    const scrollTop = scrollContainerRef.current.scrollTop;
    const scrollHeight = scrollContainerRef.current.scrollHeight;
    const clientHeight = scrollContainerRef.current.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      setReachedBottom(true);
    }
  };

  const scrollContainerRef = useRef(null);

  return (
    <Default>
      <div className="flex">
        <div className="w-full">
          <div className="px-6 py-4 border-b border-gray-300 flex items-center justify-between text-[#555555] font-semibold text-base">
            <div className="flex items-center gap-2">
              <img src={FILTER} alt="filter" />
              <p>Filter</p>
            </div>

            <div className="flex items-center gap-5">
              <div className="flex gap-2 cursor-pointer p-2 rounded-md hover:bg-[#f5f5f5]">
                <p>Pending</p>
                {_projectStoryPending.length > 0 && (
                  <span
                    className="bg-pink-600 p-1 w-[18px] h-[18px] rounded-full text-[10px]
                      flex justify-center items-center text-white ms-[-8px] mt-[-5px]"
                  >
                    {_projectStoryPending}
                  </span>
                )}
              </div>

              <button
                onClick={toggleModal}
                className="bg-[#2387FF] hover:bg-[#4F9FFF] text-white h-10
                 text-base font-semibold flex items-center justify-center rounded-md px-[16px] cursor-pointer"
              >
                <span className="flex items-center justify-center">
                  <IoIosAdd size={"24px"} />
                  Post To Stream
                </span>
              </button>

              <Modal
                className="w-[500px] h-auto"
                isOpen={isOpen}
                onClose={toggleModal}
              >
                <form onSubmit={createProjectStory}>
                  <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                    Post To Stream
                  </h1>
                  <div className="text-sm">Post Heading</div>
                  <input
                    type="text"
                    placeholder="Your post heading..."
                    name="title"
                    className="mb-3 mt-2 border border-gray-300 w-full rounded px-3 py-2
                     placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                  {/* <div className="text-sm mb-2">Stream Types</div>
                  <div className="w-full mb-2 flex justify-between p-1 border border-gray-300 rounded">
                    <select className="w-full">
                      <option>Post</option>
                      <option>Email</option>
                      <option>Transcript</option>
                      <option>Document</option>
                    </select>
                  </div> */}
                  <div className="text-sm mb-2">Post Details</div>
                  <textarea
                    name="description"
                    className="p-3 w-[652px] h-[252px] border border-gray-300 rounded placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  >
                    {/* Describe your post here... */}
                  </textarea>

                  <div className="flex justify-between items-center mt-3">
                    <div
                      className="flex items-center gap-[10px] text-base text-[#555555]
                    p-2 cursor-pointer rounded-md hover:bg-[#f5f5f5]"
                    >
                      <Icon icon="basil:attach-outline" fontSize={23} />
                      Attach Files
                    </div>
                    <div className="flex items-center text-base text-[#555555] gap-[10px]">
                      <div
                        className="cursor-pointer"
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="p-2 rounded-md hover:bg-[#f5f5f5]">
                          Cancel
                        </div>
                      </div>
                      <button
                        disabled={loaderbtn}
                        type="submit"
                        className="bg-[#2387FF] flex items-center gap-2 hover:bg-[#4F9FFF] text-white rounded-md px-[16px] py-2"
                      >
                        {loaderbtn && (
                          <Loader color="white" height="20px" width="20px" />
                        )}
                        Post To Stream
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>
            </div>
          </div>

          <div
            className="h-[calc(100vh-140px)] overflow-y-scroll overflow-hidden"
            onScroll={handleScroll}
            ref={scrollContainerRef}
          >
            {loader && (
              <div className="flex justify-center mt-5">
                <Loader />
              </div>
            )}

            {projectStories?.length === 0 ? (
              <div className="flex justify-center items-center h-[75vh] text-2xl font-semibold text-gray-500 ">
                There are no any Project Stream yet.
              </div>
            ) : (
              projectStories?.map((projectStory, index) => (
                <Post
                  key={index}
                  projectStory={projectStory}
                  getProjectStory={getProjectStories}
                />
              ))
            )}
            {/* {projectStories?.map((projectStory, index) => (
              <Post key={index} projectStory={projectStory} />
            ))}

            {projectStories?.length < 1 && (
              <div className="flex justify-center items-center h-[75vh] text-2xl font-semibold text-gray-500 ">
                There are no any Project Stream yet.
              </div>
            )} */}
          </div>
        </div>
        <div className="w-[550px] customGray">
          <AIChat />
        </div>
      </div>
    </Default>
  );
};

export default ProjectStory;
