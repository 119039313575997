import "./App.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { thunk } from "redux-thunk";
import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { Provider } from "react-redux";
import { dataReducer } from "./store/data.reducer";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import General from "./pages/Organization/General";
import Team from "./pages/Organization/Team";
import Projects from "./pages/Organization/Projects";
import Updates from "./pages/ProjectDetails/Updates";
import Descrption from "./pages/ProjectDetails/Descrption";
import Source from "./pages/ProjectDetails/Source";
import Statistics from "./pages/Statistics/Statistics";
import GeneralList from "./pages/Tasks/GeneralList";
import Members from "./pages/Tasks/Members";
import Roles from "./pages/Tasks/Roles";
import Specification from "./pages/ProjectDetails/Specification";
import ProjectStory from "./pages/ProductStory/ProjectStory";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import DataLoader from "./components/DataLoader/DataLoader";
import Home from "./pages/Home/Home";
import Dates from "./pages/Tasks/Dates";
import Lakes from "./pages/lakes/Lakes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UpdateProfile from "./pages/UpdateProfile/UpdateProfile";
import Reservoirs from "./pages/Reservoir/Reservoir";
import { configureStore } from "@reduxjs/toolkit";

const rootReducers = combineReducers({
  dataReducer: dataReducer,
});

// const store = configureStore({ rootReducers: rootReducers });
const store = legacy_createStore(rootReducers, applyMiddleware(thunk));
const clientId =
  "888862774230-jr7cbtmjtpbbf3egnrcgc5qt4s1hb086.apps.googleusercontent.com";

function App() {
  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/:organization_id/:project_id/home"
                element={<Home />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/register" element={<Register />} />

              {/* AUTH ROUTES */}
              <Route
                path="/:organization_id/organization/general"
                element={<General />}
              />
              <Route
                path="/:organization_id/:project_id/organization/general"
                element={<General />}
              />
              <Route
                path="/:organization_id/:project_id/organization/team"
                element={<Team />}
              />
              <Route
                path="/:organization_id/:project_id/organization/projects"
                element={<Projects />}
              />
              {/* <Route path="/:organization_id" element={<Home />} /> */}

              {/* <Route
              path={`/:organization_id/:project_id/project-details/updates`}
              element={<Updates />}
            /> */}

              {/* <Route
              path={`/:organization_id/:project_id/project-details/description`}
              element={<Descrption />}
            /> */}
              {/* <Route
              path={`/:organization_id/:project_id/project-details/:source`}
              element={<Source />}
            /> */}
              <Route
                path={`/:organization_id/:project_id/project-details/:source`}
                element={<Lakes />}
              />
              {/* <Route
              path={`/:organization_id/:project_id/project-details/specification`}
              element={<Specification />}
            /> */}
              <Route
                path="/:organization_id/:project_id/project-stream"
                element={<ProjectStory />}
              />

              {/* <Route
              path="/:organization_id/:project_id/tasks"
              element={<InProgress />}
            /> */}

              <Route
                path="/:organization_id/:project_id/tasks/general-list"
                element={<GeneralList />}
              />
              <Route
                path="/:organization_id/:project_id/tasks/members"
                element={<Members />}
              />
              <Route
                path="/:organization_id/:project_id/tasks/roles"
                element={<Roles />}
              />
              <Route
                path="/:organization_id/:project_id/tasks/dates"
                element={<Dates />}
              />
              <Route
                path="/:organization_id/:project_id/reservoirs/:document"
                element={<Reservoirs />}
              />
              <Route
                path="/:organization_id/:project_id/statistics"
                element={<Statistics />}
              />
            </Routes>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </BrowserRouter>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
