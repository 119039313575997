import React, { useState, useRef, useEffect } from "react";
import Default from "../../components/theme/Default/Default";
import { FaPlus } from "react-icons/fa";
import moment from "moment";
import AIChat from "../../components/AIChat";
import {
  ADDONE,
  EDIT,
  HISTORY,
  LINK,
  REFRESH,
  RESERVOIRS,
  SAVE,
  UNDO,
} from "../../images";
import "../../components/MarkdownAiResponse/ai-response.css";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $createLinkNode } from "@lexical/link";
import { $createListItemNode, $createListNode } from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";

import PlaygroundNodes from "./nodes/PlaygroundNodes";
import { FlashMessageContext } from "./context/FlashMessageContext";
import { SettingsContext, useSettings } from "./context/SettingsContext";
import { SharedAutocompleteContext } from "./context/SharedAutocompleteContext";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";

import Editor from "./Editor";
import { toast } from "react-toastify";
import PostRequest from "../../service/PostRequest";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createImageNode } from "./nodes/ImageNode";

import {} from // $createParagraphNode,
// $getRoot,
// $getSelection,
// $isElementNode,
// $isParagraphNode,
// $isRangeSelection,
// $isTextNode,
"lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import JoditEditor from "jodit-react";
import { IoIosAdd } from "react-icons/io";
import { Icon } from "@iconify/react/dist/iconify.js";
import AllResevoirs from "./AllResevoirs";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[26px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const $prepopulatedRichText = (description) => {
  console.log("IN FUNCTION");
  const root = $getRoot();
  if (root.getFirstChild() === null) {
    const heading = $createHeadingNode("h1");
    heading.append($createTextNode("Welcome to the playground"));
    root.append(heading);
    const quote = $createQuoteNode();
    quote.append(
      $createTextNode(
        `In case you were wondering what the black box at the bottom is – it's the debug view, showing the current state of the editor. ` +
          `You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting.`
      )
    );
    root.append(quote);
    const paragraph = $createParagraphNode();
    console.log("description.....", paragraph);
    paragraph.append(
      // $createTextNode("The playground is a demo environment built with "),
      // $createTextNode("@lexical/react").toggleFormat("code"),
      // $createTextNode("."),
      // $createTextNode(" Try typing in "),
      // $createTextNode("some text").toggleFormat("bold"),
      // $createTextNode(" with "),
      // $createTextNode("different").toggleFormat("italic"),
      // $createTextNode(" formats.")
      $createTextNode(description || "Welcome to the playground")
    );
    root.append(paragraph);
    const paragraph2 = $createParagraphNode();
    paragraph2.append(
      $createTextNode(
        "Make sure to check out the various plugins in the toolbar. You can also use #hashtags or @-mentions too!"
      )
    );
    root.append(paragraph2);
    const paragraph3 = $createParagraphNode();
    paragraph3.append(
      $createTextNode(`If you'd like to find out more about Lexical, you can:`)
    );
    root.append(paragraph3);
    const list = $createListNode("bullet");
    list.append(
      $createListItemNode().append(
        $createTextNode(`Visit the `),
        $createLinkNode("https://lexical.dev/").append(
          $createTextNode("Lexical website")
        ),
        $createTextNode(` for documentation and more information.`)
      ),
      $createListItemNode().append(
        $createTextNode(`Check out the code on our `),
        $createLinkNode("https://github.com/facebook/lexical").append(
          $createTextNode("GitHub repository")
        ),
        $createTextNode(`.`)
      ),
      $createListItemNode().append(
        $createTextNode(`Playground code can be found `),
        $createLinkNode(
          "https://github.com/facebook/lexical/tree/main/packages/lexical-playground"
        ).append($createTextNode("here")),
        $createTextNode(`.`)
      ),
      $createListItemNode().append(
        $createTextNode(`Join our `),
        $createLinkNode("https://discord.com/invite/KmG4wQnnD9").append(
          $createTextNode("Discord Server")
        ),
        $createTextNode(` and chat with the team.`)
      )
    );
    root.append(list);
    const paragraph4 = $createParagraphNode();
    paragraph4.append(
      $createTextNode(
        `Lastly, we're constantly adding cool new features to this playground. So make sure you check back here when you next get a chance :).`
      )
    );
    root.append(paragraph4);
  } else {
    console.log("GERERERER");
  }
};

const Reservoirs = () => {
  // const [editor] = useLexicalComposerContext();
  const [isOpen, setIsOpen] = useState(false);
  const [editReservoir, setEditReservoir] = useState(false);
  const [title, setTitle] = useState("");
  const [reservoirs, setReservoirs] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState({});
  // const [documentContent, setDocumentContent] = useState([]);
  // const editorRef = useRef(null);

  const [initialConfig, setInitialConfig] = useState(false);
  const [activeDocument, setActiveDocument] = useState({});
  const [content, setContent] = useState("");
  const editor = useRef(null);

  const [openEditor, setOpenEditor] = useState(false);
  const [reservoirType, setReservoirType] = useState("Document");

  const params = useParams();
  const navigate = useNavigate();

  const {
    settings: { isCollab, emptyEditor, measureTypingPerf },
  } = useSettings();

  const handelTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleReservoir = (option) => {
    setReservoirType(option);
  };

  const createReservoir = async () => {
    const data = {
      title: title,
      project: params.project_id,
      type: reservoirType,
    };
    setLoader(true);
    try {
      const response = await PostRequest("/create/reservoir", data);
      if (response) {
        toast.success("Create Reservoirs Successfully!");
        setIsOpen(false);
        getReservoir();
        navigate(
          `/${params.organization_id}/${params.project_id}/reservoirs/${response._id}`
        );
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handelNewTitle = (event) => {
    setNewTitle(event.target.value);
  };

  const updateReservoir = async () => {
    setLoader(true);
    try {
      const data = {
        reservoir: params.document,
        title: newTitle,
      };

      const response = await PostRequest("/update/reservoirs", data);

      if (response) {
        toast.success("Update Reservoir Successfully!");
        getReservoir();
        setEditReservoir(false);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  const getReservoir = async () => {
    setLoader(true);
    try {
      const response = await PostRequest("/reservoirs", {
        project: params.project_id,
      });

      if (response) {
        setReservoirs(response);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  const serializeEditorToHTML = (editor) => {
    const root = editor.getRoot();
    const html = root.toHTML(); // You need to create a function or library that can convert this to HTML
    return html;
  };

  // const convertJSONToHTML = (json) => {
  //   // Implement the conversion logic based on your JSON structure
  //   // This is a placeholder function and needs to be adapted to your specific use case
  //   let html = "";

  //   json.nodes.forEach((node) => {
  //     // Convert each node to HTML
  //     // This is a simplified example and may need to be adapted to your needs
  //     if (node.type === "text") {
  //       html += `<p>${node.text}</p>`;
  //     }
  //     // Add more logic for different node types as needed
  //   });

  //   return html;
  // };

  const saveDocument = async () => {
    setLoader(true);
    try {
      const data = {
        reservoir: params.document,
        description: content,
      };
      const response = await PostRequest("/save/document", data);

      if (response) {
        toast.success("Save document successfully!");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (params.document === "INIT" && reservoirs.length !== 0) {
      var url = window.location.pathname;
      url = url.replace("INIT", reservoirs[0]._id);
      navigate(url);
    } else {
      const findDocument = reservoirs.find(
        (doc) => doc._id === params.document
      );
      setActiveDocument(findDocument);
      setContent(findDocument?.description);
    }
  }, [params.document, reservoirs]);

  // useEffect(() => {
  //   const index = reservoirs.findIndex((doc) => doc._id === params?.document);
  //   if (index != -1) {
  //     const document = reservoirs[index]?.description;
  //     const images = reservoirs[index]?.images?.filter((img) => img !== null); // Filter out null values

  //     setActiveDocument(reservoirs[index]);
  //     setContent(document);

  //     // setInitialConfig({
  //     //   editorState: (editor) => {
  //     //     const parser = new DOMParser();
  //     //     const root = $getRoot();
  //     //     root.clear();

  //     //     // Set document (text content)
  //     //     if (document) {
  //     //       const dom = parser.parseFromString(document, "text/html");
  //     //       const nodes = $generateNodesFromDOM(editor, dom);
  //     //       const paragraphNode = $createParagraphNode();
  //     //       nodes.forEach((n) => paragraphNode.append(n));
  //     //       root.append(paragraphNode);
  //     //     }

  //     //     // Set images
  //     //     if (images && images.length > 0) {
  //     //       images.forEach((image) => {
  //     //         const imageNode = $createImageNode({ src: image }); // Adjust according to how you handle images in Lexical
  //     //         root.append(imageNode);
  //     //       });
  //     //     }
  //     //   },
  //     //   namespace: "Playground",
  //     //   nodes: [...PlaygroundNodes],
  //     //   onError: (error) => {
  //     //     console.error(error);
  //     //   },
  //     //   theme: PlaygroundEditorTheme,
  //     // });
  //   }
  // }, [reservoirs]);

  useEffect(() => {
    setInitialConfig(false);
    getReservoir();
  }, [params.document]);

  const getActiveDocument = reservoirs?.find(
    (reservoir) => reservoir?._id == params.document
  );

  return (
    <>
      <Default>
        <div className="flex">
          <div className="w-full">
            {!openEditor && (
              <div className="px-6 py-4 border-b border-gray-300 flex items-center justify-between text-[#555555] font-semibold text-base">
                <div className="flex items-center justify-between w-full text-[#555555]">
                  <p className="text-black text-[20px]">All Reservoirs</p>

                  {/* <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="p-3 flex items-center justify-between rounded-md hover:bg-[#F5F5F5] h-[40px] w-[40px] cursor-pointer"
                >
                  <FaPlus size={14} />
                </button> */}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="bg-[#2387FF] hover:bg-[#4F9FFF] text-white h-10
                    text-base font-semibold flex items-center justify-center rounded-md px-[16px] cursor-pointer"
                  >
                    <span className="flex items-center justify-center">
                      <IoIosAdd size={"24px"} />
                      New Reservoir
                    </span>
                  </button>
                </div>
              </div>
            )}

            {loader && (
              <div className="flex justify-center mt-5">
                <Loader />
              </div>
            )}

            {/* ALL RESERVOIRS TABLE */}
            {reservoirs?.length === 0 ? (
              <div className="flex justify-center items-center h-[75vh] text-2xl font-semibold text-gray-500 ">
                There are no any Reservoir yet.
              </div>
            ) : (
              params.document !== "INIT" &&
              !openEditor && (
                <AllResevoirs
                  reservoirs={reservoirs}
                  setOpenEditor={setOpenEditor}
                  getReservoir={getReservoir}
                />
              )
            )}

            <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
              <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                Create Reservoir
              </h1>
              <p className="w-[512px] text-[#555555] text-[14px]">
                You can create reservoir documents to store your project’s
                information and use them as a source for the Lakes.
              </p>

              <div className="text-sm mt-6 text-[#555555] font-semibold">
                Reservoir Name
                <span className="text-pink-700 text-[16px] ps-1">*</span>
              </div>

              <input
                onChange={handelTitle}
                type="text"
                placeholder="Your Reservoir Name..."
                name="name"
                className="mb-3 mt-2 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />

              <div className="text-sm mb-2 mt-1 text-[#555555] font-semibold">
                Reservoir Type
                <span className="text-pink-700 text-[16px] ps-1">*</span>
              </div>

              <div
                className="border-gray-500 border w-full rounded placeholder-font-normal
                           rounded-t-md focus:outline-none focus:z-10 sm:text-sm"
              >
                <select
                  className="border border-gray-500 w-full rounded font-semibold text-[#555555] px-3 py-2
                  placeholder-font-normal rounded-t-md focus:outline-none focus:z-10 sm:text-sm"
                  onChange={(e) => handleReservoir(e.target.value)}
                  value={reservoirType}
                >
                  <option value="Document">Document</option>
                  <option value="Data Sheet">Data Sheet </option>
                </select>
              </div>

              <div className="flex justify-end items-center mt-4">
                <div className="flex items-center text-base text-[#555555] gap-[10px]">
                  <button
                    onClick={createReservoir}
                    className="flex items-center gap-2 bg-[#2387FF] text-white rounded-md px-[16px] py-2"
                  >
                    {loader && (
                      <Loader color="white" height="17px" width="17px" />
                    )}
                    Create Reservoir
                  </button>
                </div>
              </div>
            </Modal>

            {params.document !== "INIT" && openEditor && (
              <div className="p-6 border-b border-gray-300 flex items-center justify-between text-[#555555] text-base">
                <div className="flex items-center gap-4">
                  {/* <div className="flex items-center gap-4">
                    <img src={UNDO} alt="refresh left" />
                    <img src={REFRESH} alt="refresh" />
                  </div> */}
                  {/* <div className="border-r border-[#B9B9B9] p-2 h-10"></div> */}

                  <button onClick={() => setOpenEditor(!openEditor)}>
                    <Icon icon="fontisto:arrow-left" color="black" />
                  </button>
                  <div>
                    <p className="text-black text-[18px] font-bold">
                      {getActiveDocument?.title}
                    </p>

                    <p className="text-[#555555]">
                      Edited:
                      {moment(getActiveDocument?.updatedAt).format(
                        " DD MMM, YYYY [at] hh:mm A"
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <button className="flex items-center gap-2 p-2 cursor-pointer rounded-md text-[16px] font-semibold hover:bg-[#f5f5f5]">
                    <img src={LINK} alt="link" />
                    Share
                  </button>

                  <button className="ms-2 flex gap-2 p-2 cursor-pointer font-semibold rounded-md text-[16px] hover:bg-[#f5f5f5]">
                    <img src={HISTORY} alt="history" />
                    History
                  </button>

                  <button
                    onClick={() => setEditReservoir(!editReservoir)}
                    className="flex gap-2 p-2 cursor-pointer font-semibold rounded-md text-[16px] hover:bg-[#f5f5f5]"
                  >
                    <img src={EDIT} alt="edit" />
                    Setting
                  </button>

                  <Modal
                    isOpen={editReservoir}
                    onClose={() => setEditReservoir(!editReservoir)}
                  >
                    <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                      Edit Reservoir
                    </h1>
                    <p className="w-[512px] text-[#555555] text-[14px]">
                      You can create reservoir documents to store your project’s
                      information and use them as a source for the Lakes.
                    </p>

                    <div className="text-sm mt-6 text-[#555555] font-semibold">
                      Reservoir Name
                      <span className="text-pink-700 text-[16px] ps-1">*</span>
                    </div>

                    <input
                      onChange={handelNewTitle}
                      defaultValue={activeDocument?.title}
                      type="text"
                      name="name"
                      className="mb-3 mt-2 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />

                    <div className="flex justify-end items-center mt-3">
                      <div className="flex items-center text-base text-[#555555] gap-[10px]">
                        <button
                          onClick={updateReservoir}
                          className="flex items-center gap-2 bg-[#2387FF] text-white rounded-md px-[16px] py-2"
                        >
                          {loader && (
                            <Loader color="white" height="17px" width="17px" />
                          )}
                          Save Reservoir
                        </button>
                      </div>
                    </div>
                  </Modal>

                  <button
                    onClick={saveDocument}
                    className="cursor-pointer flex items-center gap-2 hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-[18px] py-[9px]"
                  >
                    {loader && (
                      <Loader color="white" height="17px" width="17px" />
                    )}
                    <div className="flex items-center gap-2.5">
                      <img src={SAVE} alt="add one" />
                      Save
                    </div>
                  </button>
                </div>
              </div>
            )}

            {/* overflow-auto flex-grow */}
            {/* {initialConfig && (
              <div className="">
                <SettingsContext>
                  <FlashMessageContext>
                    <LexicalComposer initialConfig={initialConfig}>
                      <SharedHistoryContext>
                        <TableContext>
                          <SharedAutocompleteContext>
                            <Editor setDescription={setDescription} />
                          </SharedAutocompleteContext>
                        </TableContext>
                      </SharedHistoryContext>
                    </LexicalComposer>
                  </FlashMessageContext>
                </SettingsContext>
              </div>
            )} */}

            {params.document !== "INIT" && openEditor && (
              <JoditEditor
                ref={editor}
                value={content}
                onChange={(newContent) => setContent(newContent)}
              />
            )}
          </div>
          <div className="w-[550px] customGray">
            <AIChat />
          </div>
        </div>
      </Default>
    </>
  );
};

export default Reservoirs;
