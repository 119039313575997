import { Icon } from "@iconify/react/dist/iconify.js";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import { RESERVOIRS } from "../../images";
import { useState } from "react";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";

const AllResevoirs = ({ reservoirs, setOpenEditor, getReservoir }) => {
  const params = useParams();

  const [activeIndex, setActiveIndex] = useState(null);

  const handleIconClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const deleteDocument = async (document) => {
    if (window.confirm("Are you sure delete this document?")) {
      try {
        const response = await PostRequest("/delete/document", {
          document: document,
        });

        if (response) {
          toast.success("Delete document successfully!");
          getReservoir();
          setActiveIndex(null);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      <div
        className="overflow-y-scroll overflow-hidden"
        style={{ height: "calc(100vh - 146px)" }}
      >
        <table className="w-full overflow-y-scroll overflow-hidden">
          <tr className="border-b border-gray-300 text-[#5F5F5F] text-[14px] font-semibold">
            <td className="w-[280px] py-4 px-6">Reservoir Name</td>
            <td className="w-[150px]">Type</td>
            <td className="w-[200px]">Created By</td>
            <td className="w-[220px]">Created On</td>
            <td className="w-[300px]">Last Edited</td>
            <td className=""></td>
          </tr>

          {reservoirs.map((reservoir, index) => (
            <tr
              key={index}
              className=" text-[#555555] border-b border-gray-300 text-[14px]"
            >
              <td className="py-3 px-3">
                <NavLink
                  to={`/${params?.organization_id}/${params?.project_id}/reservoirs/${reservoir?._id}`}
                  className="px-2 py-2 flex items-center gap-3 rounded-md cursor-default text-black font-semibold"
                >
                  {/* <img src={RESERVOIRS} alt="inbox" /> */}
                  <div
                    className={`${reservoir?.type == "Document" ? "bg-[#D3E7FF]" : "bg-[#FF99DD]"}
                                 px-2 py-2 rounded-md h-[36px] w-[36px]`}
                  >
                    <Icon
                      icon="icon-park-outline:notes"
                      fontSize={20}
                      color={
                        reservoir?.type == "Document" ? "#155199" : "#660044"
                      }
                    />
                  </div>
                  <button
                    className="cursor-pointer"
                    onClick={() => setOpenEditor(true)}
                  >
                    {reservoir?.title}
                  </button>
                </NavLink>
              </td>
              <td>{reservoir?.type}</td>
              <td>Jack</td>
              <td>{moment(reservoir.createdAt).format("DD MMM, YYYY")}</td>
              <td>
                {moment(reservoir.updatedAt).format(" DD MMM, YYYY - hh:mm A")}
              </td>

              <td className="relative">
                <Icon
                  icon="iconamoon:menu-kebab-vertical-bold"
                  fontSize={25}
                  className="cursor-pointer"
                  onClick={() => handleIconClick(index)}
                />
                {activeIndex === index && (
                  <div
                    className="absolute right-10 z-10 mt-1 w-30 p-4 origin-top-right rounded-md
                    bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {/* <p
                          className="d-flex align-items-center gap-2"
                          data-bs-target="#editModal"
                          data-bs-toggle="modal"
                        >
                          <ion-icon name="create-outline"></ion-icon> Edit
                        </p> */}
                    <button
                      className="flex items-center gap-2"
                      style={{ color: "red" }}
                      onClick={() => deleteDocument(reservoir?._id)}
                    >
                      <Icon icon="uiw:delete" color="red" fontSize={16} />
                      Delete
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default AllResevoirs;
