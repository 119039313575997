import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import {
  ADD,
  CHART_BAR,
  EDIT,
  FEEDBACK,
  HISTORY,
  HOME,
  INBOX,
  LOGOTEXT,
  LOGOUT,
  RESERVOIRS,
  SETTING,
  SOURCE,
  TASK,
  TEAM,
} from "../../../images";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PostRequest from "../../../service/PostRequest";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getNewLakes, loadGetProjects } from "../../../store/data.action";
import DataLoader from "../../DataLoader/DataLoader";
import socket from "../../../service/socket";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[26px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Sidebar = () => {
  const _getProjects = useSelector((state) => state.dataReducer.getProjects);
  const [selectedProject, setSelectedProject] = useState(_getProjects[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeProjectId = params.project_id;
  const organizationId =
    params.organization_id || window.localStorage.getItem("organization");
  const source = params.source;

  const logout = () => {
    window.localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const isActive = (path) => {
    return location.pathname.match(path);
  };

  // const onSelect = (selectedList) => {
  //   const Members = selectedList.map((option) => option._id);
  //   setSelectedMember(Members);
  // };

  // const onRemove = (selectedList) => {
  //   const Members = selectedList.map((option) => option._id);
  //   setSelectedMember(Members);
  // };

  const createProject = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.members = [];

    try {
      setLoader(true);
      const response = await PostRequest("/project/create", object);

      if (response) {
        toast.success("Project created Successfully");
        setIsOpen(false);
        dispatch(loadGetProjects(params));
        navigate(`/${organizationId}/${response._id}/project-stream`);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
    // navigate(
    //   `/${organizationId}/${event.target.value}/project-details/updates`
    // );

    navigate(`/${organizationId}/${event.target.value}/project-details/INIT`);
  };

  useEffect(() => {
    const storedProjectId = params.project_id;
    setSelectedProject(storedProjectId);

    // JOIN ROOM
    socket.emit("JOIN_PROJECT", params.project_id);
  }, [params.project_id]);

  useEffect(() => {
    socket.on("ON_NEW_PROJECT_RESPONSE", (data) => {
      dispatch(getNewLakes({ project: data.project }));
    });
  }, []);

  useEffect(() => {
    if (
      _getProjects.length != 0 &&
      window.location.href.match("/organization/general")
    ) {
      if (activeProjectId) {
        navigate(`/${organizationId}/${activeProjectId}/organization/general`);
      } else {
        navigate(
          `/${organizationId}/${_getProjects[0]._id}/organization/general`
        );
      }
    }
  }, [_getProjects]);

  return (
    <>
      <DataLoader />

      <div className="text-[#555555] font-medium w-[301px] border-r border-gray-300">
        <div className="pl-6 h-[72px] flex items-center border-b border-gray-300">
          <img src={LOGOTEXT} alt="logo" className="w-24 cursor-pointer" />
        </div>

        <div className="border-b border-gray-300 py-5 px-3">
          <ul>
            <li>
              <NavLink
                to={`/${organizationId}/${activeProjectId}/home`}
                isActive={() => isActive("/home")}
                className={`${
                  isActive("/:organization_id/:project_id/home") ? "active" : ""
                } w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]`}
                // className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]"
              >
                <img src={HOME} alt="inbox" /> Home
              </NavLink>
            </li>
            <li className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] ">
              {/* <NavLink
                to="/inbox"
                className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] "
              > */}
              <img src={INBOX} alt="inbox" /> Inbox
              {/* </NavLink> */}
            </li>
            <li>
              <NavLink
                to={`/${organizationId}/${activeProjectId}/organization/general`}
                isActive={() =>
                  isActive("/organization") ||
                  isActive("/organization/general") ||
                  isActive("/organization/team") ||
                  isActive("/organization/projects")
                }
                className={`${
                  isActive("/organization") ||
                  isActive("/organization/general") ||
                  isActive("/organization/team") ||
                  isActive("/organization/projects")
                    ? "active"
                    : ""
                } w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]`}
              >
                <img src={TEAM} alt="inbox" /> Organization
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="py-5 px-3 border-b border-gray-300">
          <div className="flex justify-between items-center mb-2.5">
            <p className="text-[#878787] text-[14px]">Active Project</p>
            <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
              {/* + Add Project */}
              <img src={ADD} />
            </div>
          </div>
          <Modal
            className="w-[500px] h-auto"
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
          >
            <form onSubmit={createProject}>
              <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                Add Project
              </h1>
              <div className="text-sm">Project Heading</div>
              <input
                type="text"
                placeholder="Your post heading..."
                name="name"
                className="mb-3 mt-2 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              {/* <div className="text-sm">Members</div> */}
              {/* <Multiselect
                options={_getMembers}
                displayValue="name"
                placeholder="Select Members"
                showCheckbox={true}
                onSelect={onSelect}
                onRemove={onRemove}
              /> */}
              <div className="text-sm mb-2 mt-3">Project Details</div>
              <textarea
                name="description"
                className="p-3 w-[652px] h-[200px] border border-gray-500 rounded placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              >
                {/* Describe your Project here... */}
              </textarea>
              <div className="flex justify-end items-center mt-3">
                <div className="flex items-center text-base text-[#555555] gap-[10px]">
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="cursor-pointer"
                  >
                    <div className=" p-2 rounded-md hover:bg-[#f5f5f5]">
                      Cancel
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bg-[#2387FF] text-white rounded-md px-[16px] py-2"
                  >
                    {loader ? (
                      <Loader color="white" height="20px" width="20px" />
                    ) : (
                      "Add Project"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          <div className="mt-1 w-full px-2 h-10 border border-[#878787] text-[#555555] rounded-md flex items-center justify-between">
            <select
              name="project-name"
              id="pname"
              className="w-full h-9 cursor-pointer"
              value={selectedProject}
              onChange={handleProjectChange}
            >
              {_getProjects?.map((project, index) => (
                <option key={index} value={project._id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {activeProjectId &&
          activeProjectId != "undefined" &&
          _getProjects.length !== 0 && (
            <div
              style={{ maxHeight: "calc(100vh - 537px)" }}
              className="py-5 px-3 overflow-hidden overflow-y-auto"
            >
              <ul>
                <li>
                  <NavLink
                    to={`/${organizationId}/${activeProjectId}/project-details/INIT`}
                    isActive={
                      () =>
                        isActive("/project-details") ||
                        isActive(`/project-details/INIT`)
                      // isActive(`/${source}`)
                    }
                    className={`${
                      isActive("/project-details") ||
                      isActive(`/project-details/INIT`)
                        ? // isActive(`/${source}`)
                          "active"
                        : ""
                    } w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]`}
                  >
                    <img src={SOURCE} alt="inbox" /> Lakes
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={`/${organizationId}/${activeProjectId}/project-stream`}
                    className="w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]"
                  >
                    <img src={HISTORY} alt="inbox" />
                    Stream
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${organizationId}/${activeProjectId}/tasks/general-list`}
                    isActive={() =>
                      isActive("/tasks") ||
                      isActive("/tasks/general-list") ||
                      isActive("/tasks/members") ||
                      isActive("/tasks/roles") ||
                      isActive("/tasks/dates")
                    }
                    className={`${
                      isActive("/tasks") ||
                      isActive("/tasks/general-list") ||
                      isActive("/tasks/members") ||
                      isActive("/tasks/roles") ||
                      isActive("/tasks/dates")
                        ? "active"
                        : ""
                    } w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]`}
                  >
                    <img src={TASK} alt="inbox" /> Tasks
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${organizationId}/${activeProjectId}/reservoirs/INIT`}
                    isActive={() =>
                      isActive("/reservoirs") || isActive("/reservoirs/INIT")
                    }
                    className={`${
                      isActive("/reservoirs") || isActive("/reservoirs/INIT")
                        ? "active"
                        : ""
                    } w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5]`}
                  >
                    <img src={RESERVOIRS} alt="inbox" /> Reservoirs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${organizationId}/${activeProjectId}/statistics`}
                    className="w-[270px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] "
                  >
                    <img src={CHART_BAR} alt="inbox" /> Statistics
                  </NavLink>
                </li>
              </ul>
            </div>
          )}

        <div className="w-[301px] pt-5 px-3 pb-[24px] bottom-0 fixed border-t border-gray-300">
          <ul>
            <li className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] ">
              <img src={SETTING} alt="inbox" /> Settings
            </li>
            <li className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] ">
              <img src={FEEDBACK} alt="inbox" /> Feedback
            </li>
            <li
              className="w-[276px] my-1 h-10 px-3 rounded-md flex items-center gap-3 cursor-pointer hover:bg-[#F5F5F5] "
              onClick={logout}
            >
              <Icon icon="material-symbols:logout-rounded" fontSize={"24"} />
              {/* <img src={LOGOUT} alt="inbox" /> */}
              Logout
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
// export default React.memo(Sidebar);
