import React, { useEffect, useRef, useState } from "react";
import RollPill from "../../components/Organization/RollPill";
import Loader from "../../components/Loader";
import Org_Details from "../../components/Organization/Org_Details";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import Organization from "./Organization";
import ActivePill from "../../components/Organization/ActivePill";
import { TOGGLE } from "../../images";
import { getFirstLetter } from "../../components/Formater";
import { IoIosAdd } from "react-icons/io";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useParams } from "react-router-dom";

const Team = () => {
  const [reachedBottom, setReachedBottom] = useState(false);
  const [members, setMembers] = useState([]);
  // console.log("members", members);
  const [loader, setLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const params = useParams();

  const scrollContainerRef = useRef(null);

  const handleScroll = () => {
    const scrollTop = scrollContainerRef.current.scrollTop;
    const scrollHeight = scrollContainerRef.current.scrollHeight;
    const clientHeight = scrollContainerRef.current.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      setReachedBottom(true);
    }
  };

  const getMembers = async (isLoadMore = false) => {
    try {
      var data = {};
      if (isLoadMore) {
        data.skip = members.length;
      }

      setLoader(true);

      const response = await PostRequest("/members", data);

      if (response) {
        if (isLoadMore) {
          setMembers([...members, ...response]);
        } else {
          setMembers(response);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleIconClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const removeInviteMember = async (memberId) => {
    if (window.confirm("Are you sure member remove from organization?")) {
      try {
        const response = await PostRequest("/delete/member", {
          member: memberId,
          organization: params.organization_id,
        });

        if (response) {
          toast.success("Member Remove From Organization Successfully");
          getMembers();
          setActiveIndex(null);
        } else {
          toast.error(
            "You are owner of this organization! you can not remove your self."
          );
          setActiveIndex(null);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    if (reachedBottom) {
      getMembers(true);
      setReachedBottom(false);
    }
  }, [reachedBottom]);

  return (
    <Organization>
      {/* <div className="py-6 px-11 border-b border-gray-300 flex justify-around text-[#5F5F5F] font-semibold">
        <p className="w-[405px]">Name</p>
        <p className="w-[270px]">Email</p>
        <p className="w-[245px]">Phone Number</p>
        <p className="w-[390px]">Roles</p>
        <p className="w-[250px]">Status</p>
      </div> */}

      <div
        onScroll={handleScroll}
        ref={scrollContainerRef}
        className="h-[75vh] overflow-y-scroll overflow-hidden"
      >
        {/* {members?.map((member, index) => (
          <Org_Details
            key={index}
            name={member.name}
            email={member.email}
            phone={member.phoneNumber}
            status={member.status}
            role={member.roles?.map((role, index) => (
              <RollPill key={index} role={role.name} />
            ))}
          />
        ))} */}

        <table className=" w-full text-[#555555] border-b border-gray-200">
          <tr className="border-b border-gray-200 text-[#5F5F5F] font-semibold">
            <td className="px-11 py-6 w-[360px]">Name</td>
            <td className="w-[240px]">Email</td>
            <td className="w-[220px]">Phone Number</td>
            <td className="w-[340px]">Role Description</td>
            <td className="w-[400px]">Permission</td>
          </tr>
          {members?.map((member, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="px-6 py-4 w-[360px]">
                <div className="flex gap-3 items-center  font-semibold text-base">
                  <div className="w-10 h-10 rounded-full bg-violet-500 flex items-center justify-center">
                    <p className="font-bold text-white text-xl">
                      {getFirstLetter(member.name)}
                    </p>
                  </div>
                  <p>{member.name}</p>
                </div>
              </td>
              <td className="w-[240px]">{member.email}</td>
              <td className="w-[220px]">{member.phoneNumber}</td>
              <td className="w-[340px] text-[14px]">
                {/* <ActivePill title={member.status} /> */}
                <p className=" tracking-normal py-3">
                  Does project management related works. Meet with members.
                  Assist members in their works. Track tasks. get reports of the
                  tasks and track progress of each projects.
                </p>
              </td>
              <td className="w-[400px]">
                {member.roles?.map((role, index) => (
                  <RollPill key={index} role={role.name} />
                ))}
              </td>
              <td>
                <div className="w-[70px] cursor-pointer">
                  <Icon
                    icon="iconamoon:menu-kebab-vertical-bold"
                    fontSize={30}
                    className="cursor-pointer"
                    onClick={() => handleIconClick(index)}
                    // onClick={() => setActiveIndex(!activeIndex)}removeInviteOrganization
                  />
                </div>

                {index === activeIndex && (
                  <div
                    className="rounded-md py-2 origin-top-right 
                       bg-white shadow-lg ring-1 ring-black ring-opacity-5 pl-2 pr-8 absolute ms-[-115px]"
                  >
                    <button
                      className="text-red-600  flex items-center gap-2"
                      onClick={() => removeInviteMember(member._id)}
                    >
                      <Icon icon="weui:delete-outlined" fontSize={20} />
                      <span className="font-semibold text-[18px]">Delete</span>
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </table>

        <div className="flex justify-center mt-5">{loader && <Loader />}</div>
      </div>
    </Organization>
  );
};

export default Team;
